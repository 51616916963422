<template>
  <div v-if="!this.myApartment.sold" class="org-apartments-single">

    <CompSlider id="slider-apartment" class="margin-bottom-small" :slides="this.myApartment.images" />

    <section class="org-section margin-bottom-medium type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-08">
          <h5 class="type-headline-pre top-headline">{{ this.myApartment.name }}</h5>
          <h1 class="type-headline-01">{{ this.myApartment.title }}</h1>    
        </div>

        <div class="grid-s-04-span-05">
          <p class="type-copy">
            {{ this.myApartment.description }} Die Hommage au Cottage Eigentumswohnungen bestechen durch smarte Grundrissgestaltung, die in Kombination mit großzügigen Freiflächen für lichtdurchflutete Räume und ein angenehmes Wohnklima sorgen. Die clevere Architektur des Hauses bietet den zukünftigen Bewohnern und Bewohnerinnen außerdem viel Rückzugsraum und Lebensqualität in ruhiger Wohnlage.
          </p>
          <p class="download-pdf" v-if="this.myApartment.pdf">
          <CompButton 
            :linkIntern="false"
            :linkExtern="true"
            textColor="coral"
            target="_blank"
            :url="this.myApartment.pdf"
            text="Datenblatt herunterladen (PDF)" />
          </p>
        </div>
        <div  v-if="this.myApartment.floormap" 
              class="floor-map grid-s-09-span-03">
              <img :src="this.myApartment.floormap" :alt="'Übersicht: ' + this.myApartment.floor">
        </div>
      <div class="grid-s-02-span-12">
        <ul class="fact-list type-center type-bluenight">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'fact-list-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="none" stroke="#e6f2ff" stroke-miterlimit="10" d="M85.84 41.84L50 69.67 14.16 41.84 50 12.67z"/><path fill="none" stroke="#e6f2ff" stroke-miterlimit="10" d="M77.91 55.86l7.93 6.46L50 90.15 14.16 62.32l8.41-6.85"/></svg>
            <span class="type-coral">Geschoß</span>
            {{ this.myApartment.floor }}
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'fact-list-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M12 12h76v76H50V50H12z"/><path class="st0" d="M50 37.3V50h13.1M50 12v13.1M88 50H75.3"/></svg>
            <span class="type-coral">Zimmer</span>
            {{ this.myApartment.rooms }}
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'fact-list-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M50 12v76M42 12h16M42 88h16M42 50h16M46 31h8M46 69h8"/></svg>
            <span class="type-coral">Größe</span>
            {{ this.myApartment.size | areaFormat }}
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'fact-list-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76.1 24.6l-2.9-10.3-9.3-1.9L50 5.2l-13.8 7.4-9.4 2.7-2.8 9.3-5.6 10.9 2.9 8.2 1.1 6.3 3.7 3.6 6.1 8.9 13 2.4-2 28.4 1.4 1.4h10.6l1.4-1.4-2-28.5H56L68.7 62l5.4-8.3 3.1-3.6 1.6-6.1 2.9-8.3-5.6-11.1z"/></svg>
            <span class="type-coral">Freifläche</span>
            <div v-if="this.myApartment.loggia">
              {{ this.myApartment.loggia | areaFormat }} Loggia
            </div>
            <div v-if="this.myApartment.balcony">
              {{ this.myApartment.balcony | areaFormat }} Balkon
            </div>
            <div v-if="this.myApartment.terrace">
              {{ this.myApartment.terrace | areaFormat }} Terrasse
            </div>
            <div v-if="this.myApartment.garden">
              {{ this.myApartment.garden | areaFormat }} Garten
            </div>
            <div v-if="this.myApartment.rooftop">
              {{ this.myApartment.rooftop | areaFormat }} Dachterasse
            </div>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'fact-list-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M67.3 58c-.5 3.7-1.3 6.7-2.5 9.1-1.2 2.4-2.8 4.2-4.7 5.4-1.9 1.2-4.2 1.8-6.9 1.8-1.9 0-3.7-.3-5.3-1-1.6-.6-3-1.6-4.2-2.9-1.2-1.3-2.2-2.9-3-4.8-.5-1.2-.9-2.4-1.2-3.8h15.1l.8-7.9h-17c-.1-1.3-.1-2.5-.1-3.9s0-2.7.1-4H56l.8-7.9H39.4c.3-1.3.7-2.6 1.2-3.7.8-2 1.9-3.6 3.1-4.8 1.2-1.3 2.6-2.2 4.1-2.9 1.5-.6 3.3-1 5.4-1 2.4 0 4.6.6 6.5 1.7 1.9 1.1 3.5 2.9 4.6 5.3 1.2 2.4 2.1 5.3 2.7 8.8l20-3.9c-1.1-6.3-3.2-11.7-6.3-16-3-4.3-6.9-7.5-11.5-9.7-4.7-2.2-10-3.3-16.1-3.3-4.4 0-8.4.6-12.2 1.9-3.7 1.3-7.1 3.1-9.9 5.4-2.9 2.3-5.3 5.2-7.3 8.5-2 3.4-3.6 7.2-4.7 11.5-.2.7-.3 1.4-.5 2.1h-10v8h9c-.1 1.3-.1 2.6-.1 4 0 1.3 0 2.6.1 3.9H8.6v7.9h9.9c.1.8.3 1.5.4 2.2 1 4.3 2.5 8.1 4.5 11.5 2 3.3 4.4 6.2 7.3 8.5 2.9 2.3 6.2 4.1 9.9 5.4 3.7 1.3 7.9 1.9 12.5 1.9 6.2 0 11.6-1.1 16.3-3.3 4.7-2.2 8.5-5.5 11.6-9.8 3.1-4.4 5.2-9.8 6.3-16.4l-20-3.8z"/></svg>
            <span class="type-coral">Kaufpreis</span>
            {{ this.myApartment.price | priceFormat }}
          </li>
        </ul>
      </div>
    </div>
    </section>

    <section class="org-section line-bottom margin-bottom-big type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-03">
          <img  style="width: 100%; height: auto;"
                :src="this.imgBathroom.img.images[Math.round(this.imgBathroom.img.images.length / 2)].path"
                :srcSet="this.imgBathroom.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.imgBathroom.alt">
        </div>
        <div class="grid-s-07-span-05"  style="align-self: end;">
          <h5 class="type-headline-pre">Ausstatttung</h5>
          <h1 class="type-headline-01">Qualität bis ins kleinste Detail</h1>  
        </div>
        <div class="grid-s-06-span-04">
          <p class="type-copy">Die hochwertige Ausstattung und massive Bauweise der Hommage au Cottage Eigentumswohnungen garantieren langlebige Qualität. Die zeitlose Architektur integriert moderne Features wie Fußbodenheizung, zeitgenössische Armaturen und außenliegenden Sonnenschutz bei jedem Fenster.</p>
        </div>
        <div class="grid-s-09-span-03 grid-facts type-green">
          <h5 class="type-headline-pre">Facts</h5>
          <ul class="type-copy">
            <li>Hochwertige Echtholz-Parkettböden</li>
            <li>Fußbodenheizung</li>
            <li>Holz-Alu-Fenster mit Sonnenschutz</li>
            <li>Französische Fenster</li>
            <li>Bäder mit Feinsteinzeugfliesen, Dusche und/oder Badewanne</li>
            <li>Bodengleich geflieste Duschen</li>
            <li>Qualitative hochwertige Armaturen</li>
            <li>Kellerabteile</li>
            <li>Natürlich belichtetes Stiegenhaus</li>
            <li>Grüner Innenhof</li>
          </ul>
        </div>
        <div class="grid-s-08-span-03">
          <CompButton 
            :linkIntern="false"
            :linkExtern="true"
            textColor="green"
            :url="`mailto:?subject=${this.myApartment.name} Hommage au Cottage, Messerschmidtgasse 3, 1180 Wien&amp;body=${this.myApartment.rooms} Zimmer Wohnung im Hommage au Cottage. https://www.hommageaucottage.at/wohnungen/${this.myApartment.slug}`"
            text="Wohnung per Mail teilen"
            icon="mail"
            :iconOutline="true"/>
          <CompButton 
            :linkIntern="false"
            :linkExtern="true"
            target="_blank"
            textColor="green"
            :url="`https://api.whatsapp.com/send?text=https://www.hommageaucottage.at/wohnungen/${this.myApartment.slug}`"
            dataAction="share/whatsapp/share"
            text="Wohnung per WhatsApp teilen"
            icon="whatsapp"
            :iconFill="true"/>
        </div>
      </div>
    </section>

    <CompInquiry />    
    <CompContact />
    <CompFooter />

  </div>
</template>
<script>
  import CompSlider from '@/components/CompSlider.vue'
  import CompInquiry from '@/components/CompInquiry.vue';
  import CompContact from '@/components/CompContact.vue';

  export default {
    name: 'apartments-single',
    data() {
      return {
         imgBathroom: {
           img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
           alt: 'Hommage au Cottage Beispielbadezimmer',
           objectPostion: 'center',
         }
      }
    },
    metaInfo() {
      return {
        title: `${this.myApartment.name}`,
        htmlAttrs: {
          lang: 'de',
        },
        meta: [
          {name: 'description', content: `${this.myApartment.rooms} Zimmer Wohnung mit ${this.myApartment.specials} im Hommage au Cottage in der Messerschmidtgasse 3, 1180 Wien. ${this.myApartment.description}`},
        ],
      }
    },
    props : {
      id: String
    },
    beforeMount() {
      if (this.myApartment.sold) {
        this.$router.push({ name: 'apartments' })
      }
    },
    computed : {
      myApartment() {
        let allApartments = this.$store.getters.getterApartments;
        return allApartments[this.id];
      }
    },
    components: {
      CompSlider,
      CompInquiry,
      CompContact
    },
    mounted() {
      // console.log(this.myApartment)
    },
    filters: {
      priceFormat(value) {
        let val = (value/1).toFixed(0)
        return "€ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",—"
      },
      areaFormat(value) {
        return Math.floor(value) + "  qm"
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '@/assets/css/typography.scss';

.top-headline {
  font-size: 170% !important;
  span {
    display: block;
    font-variation-settings: 'wght' 400, 'wdth' 180;
  }
}

#slider-apartment {
  margin-top: calc(var(--spacer)*5);

  @media screen and (min-width: #{$breakpoint-tablet}) {
    margin-top: 0;
  }
}

h1.type-headline-01 {
  hyphens: auto;
}

.download-pdf {
  margin-top: 2rem;
  max-width: 500px;
}

.floor-map {
  img {
    width: 100% !important;
    height: auto;
  }
}

.fact-list {
  display: grid;
  grid-gap: var(--spacer);
  grid-template-columns: repeat(1, auto);
  padding: 0 var(--spacer);

  li {
    position: relative;
    min-height: calc(160px + var(--spacer));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @extend .type-headline-03;
    span {
      font-size: 1rem;
      text-transform: uppercase;
      display: block;
    }

    .fact-list-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -10;

      path {
        fill: none !important;
        stroke-width: 2px !important;
        stroke: var(--c-blueblush-07) !important;
      }
    }
  }

  @media screen and (min-width: #{$breakpoint-tablet}) {
   grid-template-columns: repeat(3, 1fr);
 }
 @media screen and (min-width: #{$breakpoint-laptop}) {
   grid-template-columns: repeat(5, 1fr);
 }
}

.grid-facts {
  opacity: .6;

  *:not(:last-child) {
    margin-bottom: calc( var(--spacer)*.2 );
  }

  li {
    position: relative;

    &:before {
      content: "–";
      position: absolute;
      transform: translateX(-100%);
      padding-right: calc( var(--spacer)*.1 )
    }
  }
}

.org-contact {
  margin-bottom: 0 !important;
}

</style>